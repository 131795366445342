import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { IntegrationType } from '../../gql-global'
import IntegrationLogo from '../IntegrationLogo'

const useStyles = (props: IntegrationSentWithProps) => {
  const styles = {
    sentWording: {
      color: '#7C7CA1',
    },
  }
  let integrationStyles
  switch (props.integrationType) {
    case IntegrationType.Mandrill:
      integrationStyles = { height: 24, margin: '0 25px 0 7px' }
      break
    case IntegrationType.Zaius:
      integrationStyles = { height: 21, margin: '0 25px 4px 9px', width: 100 } // Optimizely
      break
    case IntegrationType.Sendinblue:
      integrationStyles = { height: 15, margin: '0 25px 4px 9px' }
      break
    case IntegrationType.Yotpo:
      integrationStyles = { height: 18, margin: '0 25px 2px 10px' }
      break
    case IntegrationType.YotpoViz:
      integrationStyles = { height: 18, margin: '0 25px 2px 10px' }
      break
    case IntegrationType.Growave:
      integrationStyles = { height: 18, margin: '0 25px 0 7px' }
      break
    case IntegrationType.Klaviyo:
      integrationStyles = { height: 18, margin: '0 25px 3px 7px' }
      break
    case IntegrationType.Moengage:
      integrationStyles = { height: 14, margin: '0 20px 2px 7px' }
      break
    case IntegrationType.Emarsys:
      integrationStyles = { height: 18, margin: '0 25px 4px 7px' }
      break
    case IntegrationType.Braze:
      integrationStyles = { height: 18, margin: '0 25px 1px 7px' }
      break
    case IntegrationType.LoyaltyLion:
      integrationStyles = { height: 23, margin: '0 25px 0 7px' }
      break
    case IntegrationType.Smile:
      integrationStyles = { height: 15, margin: '0 25px 6px 7px' }
      break
    case IntegrationType.Shopify:
      integrationStyles = { height: 19, margin: '0 25px 3px 10px' }
      break
    case IntegrationType.Tremendous:
      integrationStyles = { height: 11, margin: '0 25px 6px 10px' }
      break
    case IntegrationType.Ometria:
      integrationStyles = { height: 24, margin: '0 25px 0 10px' }
      break
    default:
      integrationStyles = { height: 32, margin: '0 25px -4px 7px' }
  }
  return makeStyles({ ...styles, integration: integrationStyles })
}

interface IntegrationSentWithProps {
  integrationType?: IntegrationType
  hideWording?: boolean
}

export default function IntegrationSentWith(props: IntegrationSentWithProps) {
  const classes = useStyles(props)()
  return (
    <Box display="flex" alignItems="flex-end" height={40}>
      {!props.hideWording && (
        <Typography variant="body2" className={classes.sentWording}>
          Sent with
        </Typography>
      )}
      <IntegrationLogo
        integrationType={props.integrationType ?? IntegrationType.Webhook}
        className={classes.integration}
      />
    </Box>
  )
}
