import React from 'react'
import { Filters } from './use-filter-params'
import { CustomerFilterType } from '../gql-global'
import SavedFilterBar from '../components/SavedFilterBar'
import { useDeleteFilterMutation } from '../mutations/operations/delete-filter.generated'
import {
  CustomerListUserDataQuery,
  CustomerListUserDataQueryVariables,
  CustomerListUserDataDocument,
} from './operations/customer-list-user-data.generated'
import produce from 'immer'
import { useCreateCustomerFilterMutation } from './operations/create-filter.generated'
import { isTypeName } from '../types/utility'
import { makeMentionTypesWhere, filterResponseMentionTypesToSet } from '../dashboard/utils'
import useHasFeature from '../hooks/useHasFeature'
import { cleanAllAnyNoneFilter, dateInclusionExclusionSaveFilter } from '../utils/filter-params'
import { useToast } from '../components/Alert/ToastProvider'
import { dateTimeRangeFilterToInput } from '../utils/date-range-helper'

interface CustomerSavedFilterBarProps {
  filters: Filters
  setFilters: (f: Filters) => void
  isDirty: boolean
  accountId?: number | null
  accountFilters?: CustomerFilterType[] | null
  isInstagramAccount: boolean
  setSaveFilterDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  saveFilterDialogOpen: boolean
}

function convertSavedFilterToFilters(savedFilter: CustomerFilterType, isInstagramAccount: boolean): Filters {
  let filters: Filters = {
    filterId: savedFilter.id,
  }
  const mentionType = filterResponseMentionTypesToSet(savedFilter.value, isInstagramAccount)

  if (savedFilter.value) {
    filters = {
      ...filters,
      postCount: savedFilter.value.postCount
        ? { gte: savedFilter.value.postCount.gte || undefined, lte: savedFilter.value.postCount.lte || undefined }
        : undefined,
      segments: savedFilter.value.segments || undefined,
      labels: savedFilter.value.labels || undefined,
      impressions: savedFilter.value.impressions
        ? { gte: savedFilter.value.impressions.gte || undefined, lte: savedFilter.value.impressions.lte || undefined }
        : undefined,
      avgEngagementRate: savedFilter.value.avgEngagementRate
        ? {
            gte: savedFilter.value.avgEngagementRate.gte || undefined,
            lte: savedFilter.value.avgEngagementRate.lte || undefined,
          }
        : undefined,
      followerCount: savedFilter.value.followerCount
        ? {
            gte: savedFilter.value.followerCount.gte || undefined,
            lte: savedFilter.value.followerCount.lte || undefined,
          }
        : undefined,
      approvedAt: savedFilter.value.approvedAt || undefined,
      postedAt: savedFilter.value.postedAt || undefined,
      maxPostedAt: savedFilter.value.maxPostedAt || undefined,
      minPostedAt: savedFilter.value.minPostedAt || undefined,
      usernameKeywords: savedFilter.value.username?.keywords || undefined,
      emailKeywords: savedFilter.value.email?.keywords || undefined,
      tagStatus: new Set(savedFilter.value?.tagStatus?.any || []),
      challengeMediaApproval: new Set(savedFilter.value?.challengeMediaApproval?.any || []),
      mediaType: new Set(savedFilter.value.mediaType?.any || []),
      rewards: new Set(savedFilter.value.rewards?.any || []),
      notesKeywords: savedFilter.value.notes?.keywords || [],
      noteCategories: new Set(savedFilter.value.noteCategories?.any || []),
      noteCreatedAt: savedFilter.value.noteCreatedAt || undefined,
      mentionType: mentionType.size > 0 ? mentionType : undefined,
      customFields: savedFilter.value.customFields || undefined,
      biographyKeywords: savedFilter.value.biography?.all?.map(k => k[0]).filter((l): l is string => !!l) || undefined,
      campaigns: savedFilter.value.campaigns || undefined,
      messageTemplate: savedFilter.value.messageTemplate || undefined,
      messageDate: savedFilter.value.messageDate || undefined,
      messageKeywords: savedFilter.value.messageKeywords || undefined,
      messageSendingMethod: savedFilter.value.messageSendingMethod || undefined,
      challengeIds: savedFilter.value?.challengeIds?.any ? { any: savedFilter.value?.challengeIds?.any } : undefined,
    }
  }
  return filters
}

const CustomerSavedFilterBar: React.FC<CustomerSavedFilterBarProps> = ({
  filters,
  isDirty,
  accountFilters,
  setFilters,
  isInstagramAccount,
  setSaveFilterDialogOpen,
  saveFilterDialogOpen = false,
}) => {
  const selectedFilter = accountFilters?.find(f => f.id === filters.filterId) || null
  const [deleteFilter] = useDeleteFilterMutation()
  const [createFilter] = useCreateCustomerFilterMutation()
  const { showToast } = useToast()
  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  function handleSelectFilter(id: string): void {
    const filter = accountFilters?.find(f => f.id === id)
    if (!filter) return
    setFilters(convertSavedFilterToFilters(filter, isInstagramAccount))
  }

  function handleSaveFilter(saveFilterName: string): void {
    const postedAtFilter = dateTimeRangeFilterToInput(filters.postedAt)
    const maxPostedAtFilter = dateTimeRangeFilterToInput(filters.maxPostedAt)
    const minPostedAtFilter = dateTimeRangeFilterToInput(filters.minPostedAt)
    const approvedAtFilter = dateTimeRangeFilterToInput(filters.approvedAt)
    const noteCreatedAtFilter = dateTimeRangeFilterToInput(filters.noteCreatedAt)

    createFilter({
      variables: {
        name: saveFilterName,
        value: {
          postCount: filters.postCount,
          segments: filters.segments
            ? { all: filters.segments?.all || undefined, none: filters.segments.none || undefined }
            : undefined,
          labels: filters.labels
            ? { all: filters.labels?.all || undefined, none: filters.labels.none || undefined }
            : undefined,
          impressions: filters.impressions,
          avgEngagementRate: filters.avgEngagementRate,
          followerCount: filters.followerCount,
          approvedAt: approvedAtFilter,
          postedAt: postedAtFilter,
          maxPostedAt: maxPostedAtFilter,
          minPostedAt: minPostedAtFilter,
          username: filters.usernameKeywords ? { keywords: filters.usernameKeywords } : undefined,
          email: filters.emailKeywords ? { keywords: filters.emailKeywords } : undefined,
          tagStatus: filters.tagStatus?.size ? { any: Array.from(filters.tagStatus) } : null,
          challengeMediaApproval: filters.challengeMediaApproval?.size
            ? { any: Array.from(filters.challengeMediaApproval) }
            : null,
          mediaType: filters.mediaType?.size ? { any: Array.from(filters.mediaType) } : undefined,
          rewards: filters.rewards?.size ? { any: Array.from(filters.rewards) } : undefined,
          notes: filters.notesKeywords ? { keywords: filters.notesKeywords } : undefined,
          noteCategories: filters.noteCategories?.size ? { any: Array.from(filters.noteCategories) } : undefined,
          noteCreatedAt: noteCreatedAtFilter,
          ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
          customFields: filters.customFields,
          biography: filters.biographyKeywords ? { all: filters.biographyKeywords.map(k => [k]) } : undefined,
          campaigns: filters.campaigns
            ? { any: filters.campaigns?.any || undefined, none: filters.campaigns.none || undefined }
            : undefined,
          messageTemplate: filters.messageTemplate
            ? { all: filters.messageTemplate?.all || undefined, none: filters.messageTemplate.none || undefined }
            : undefined,
          messageDate: dateInclusionExclusionSaveFilter(filters.messageDate),
          messageKeywords: filters.messageKeywords,
          messageSendingMethod: cleanAllAnyNoneFilter(filters.messageSendingMethod),
          challengeIds: filters.challengeIds?.any ? { any: filters.challengeIds.any } : undefined,
        },
      },
      update(proxy, { data }): void {
        const filter = data?.createFilter?.filter
        if (!filter || !isTypeName(filter, 'CustomerFilterType')) {
          return
        }
        const cachedQuery = proxy.readQuery<CustomerListUserDataQuery, CustomerListUserDataQueryVariables>({
          query: CustomerListUserDataDocument,
        })
        if (!cachedQuery?.whoami?.account?.customerFilters) {
          return
        }
        const newQueryResults = produce(cachedQuery, draft => {
          draft.whoami?.account?.customerFilters?.unshift(filter)
        })
        proxy.writeQuery<CustomerListUserDataQuery, CustomerListUserDataQueryVariables>({
          query: CustomerListUserDataDocument,
          data: newQueryResults,
        })
      },
    })
      .then(response => {
        const newFilter = response.data?.createFilter?.filter
        if (newFilter && isTypeName(newFilter, 'CustomerFilterType')) {
          setFilters(convertSavedFilterToFilters(newFilter, isInstagramAccount))
        }
      })
      .catch(() => {
        showToast({
          title: 'Error: Create Saved Search',
          message: `Something went wrong when saving this set of search filters, please try again`,
          severity: 'error',
        })
      })
  }

  function handleDeleteFilter(id: string): void {
    deleteFilter({
      variables: {
        filterId: id,
      },
      update(proxy, { data }): void {
        const filterId = data?.deleteFilter?.filter?.id
        if (!filterId) {
          return
        }

        const cachedQuery = proxy.readQuery<CustomerListUserDataQuery, CustomerListUserDataQueryVariables>({
          query: CustomerListUserDataDocument,
        })
        if (!cachedQuery?.whoami?.account?.customerFilters) {
          return
        }
        const newQueryResults = produce(cachedQuery, draft => {
          const filters = draft.whoami?.account?.customerFilters
          if (!filters) {
            return
          }
          const filterIndex = filters.findIndex(f => f.id === filterId)
          if (filterIndex > -1) {
            filters.splice(filterIndex, 1)
          }
        })

        proxy.writeQuery<CustomerListUserDataQuery, CustomerListUserDataQueryVariables>({
          query: CustomerListUserDataDocument,
          data: newQueryResults,
        })
      },
    })
      .then(data => {
        showToast({
          severity: 'success',
          title: 'Success: Filter Deleted',
          message: `The search ${data.data?.deleteFilter?.filter?.name} was deleted.`,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Deleting Filter',
          message: 'Something went wrong while deleting this filter. Please try again.',
        })
      })
  }

  return (
    <SavedFilterBar
      selectedFilter={selectedFilter}
      defaultText="All Customers"
      canSaveFilter={isDirty}
      filters={accountFilters || []}
      onDeleteFilter={handleDeleteFilter}
      onSelectSavedFilter={handleSelectFilter}
      onCreateFilter={handleSaveFilter}
      setSaveFilterDialogOpen={setSaveFilterDialogOpen}
      saveFilterDialogOpen={saveFilterDialogOpen}
    />
  )
}

export default CustomerSavedFilterBar
