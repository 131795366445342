import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  makeStyles,
  Theme,
  createStyles,
  Box,
} from '@material-ui/core'
import { TestFulfillmentInput, FulfillmentAccountType } from '../gql-global'
import { FulfillmentAccountRowFragment } from './operations/fulfillment-account-row.generated'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'

interface TestIntegrationProps {
  integration: FulfillmentAccountRowFragment
  open: boolean
  onClose(): void
  onTest(data: TestFulfillmentInput): Promise<unknown>
  testing: boolean
  result?: string
}

interface Fields {
  toEmail: string
  fromEmail: string | null
  emailTemplateName: string | null
  loyaltyTierId: string | null
  templateId: string | null
  discountCode: string | null
  discountCodeFieldName: string | null
  igUsername: string | null
  subject: string | null
  eventId: string | null
  value: number | null
  campaignId: string | null
}

type FieldNames = keyof Fields
const fieldSchema: Record<
  FieldNames,
  yup.StringSchema | yup.StringSchema<string | null | undefined> | yup.NumberSchema
> = {
  toEmail: yup.string().email().required(),
  fromEmail: yup.string().email().required(),
  emailTemplateName: yup.string().required(),
  loyaltyTierId: yup.string(),
  templateId: yup.string().required(),
  discountCode: yup.string().nullable().notRequired(),
  discountCodeFieldName: yup
    .string()
    .when('discountCode', (other: string | null, schema: yup.StringSchema) =>
      !!other ? schema.required() : schema.notRequired().nullable(),
    ),
  igUsername: yup.string(),
  subject: yup.string().required(),
  eventId: yup.string().required(),
  value: yup.number().integer().positive().required(),
  campaignId: yup.string().nullable().notRequired(),
}
const fieldMap: Record<FulfillmentAccountType, Set<FieldNames>> = {
  [FulfillmentAccountType.Mandrill]: new Set<FieldNames>([
    'toEmail',
    'fromEmail',
    'emailTemplateName',
    'discountCode',
    'discountCodeFieldName',
  ]),
  [FulfillmentAccountType.Yotpo]: new Set<FieldNames>(['eventId', 'toEmail']),
  [FulfillmentAccountType.Growave]: new Set<FieldNames>(['toEmail', 'eventId', 'value']),
  [FulfillmentAccountType.Zaius]: new Set<FieldNames>(['toEmail', 'discountCode', 'loyaltyTierId']),
  [FulfillmentAccountType.Sendinblue]: new Set<FieldNames>(['toEmail', 'fromEmail', 'templateId']),
  [FulfillmentAccountType.Webhook]: new Set<FieldNames>(['toEmail', 'loyaltyTierId', 'igUsername', 'discountCode']),
  [FulfillmentAccountType.Klaviyo]: new Set<FieldNames>(['toEmail', 'eventId', 'discountCode', 'igUsername']),
  [FulfillmentAccountType.Moengage]: new Set<FieldNames>(['toEmail', 'discountCode', 'loyaltyTierId']),
  [FulfillmentAccountType.Emarsys]: new Set<FieldNames>(['eventId', 'toEmail', 'discountCode']),
  [FulfillmentAccountType.Braze]: new Set<FieldNames>([
    'toEmail',
    'templateId',
    'discountCodeFieldName',
    'discountCode',
  ]),
  [FulfillmentAccountType.LoyaltyLion]: new Set<FieldNames>(['eventId', 'toEmail']),
  [FulfillmentAccountType.Smile]: new Set<FieldNames>(['eventId', 'toEmail']),
  [FulfillmentAccountType.Tremendous]: new Set<FieldNames>(['toEmail']),
  [FulfillmentAccountType.Ometria]: new Set<FieldNames>(['toEmail']),
  [FulfillmentAccountType.Bluecore]: new Set<FieldNames>(['toEmail', 'campaignId']),
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginRight: theme.spacing(2),
    },
  }),
)

const TestIntegration: React.FC<TestIntegrationProps> = ({ integration, open, onClose, testing, result, onTest }) => {
  const classes = useStyles()
  const fields = fieldMap[integration.accountType]
  const schema = yup.object(Array.from(fields).reduce((a, v) => ({ ...a, [v]: fieldSchema[v] }), {}))
  const onSubmitForm = async (values: Fields): Promise<unknown> => {
    return onTest({ fulfillmentAccountId: integration.id.toString(), ...values })
  }

  function displayTestResult(result: string | undefined, accountType: FulfillmentAccountType): React.ReactElement {
    if (!!result && accountType === FulfillmentAccountType.Growave) {
      if (result.includes('200')) {
        return <Box color="green">Success!</Box>
      }
      if (result.includes('404')) {
        return (
          <Box color="red">
            Please make sure you are testing with a registered rewards member email AND that an action is created with
            rule_type of &apos;loudcrowd&apos;.
          </Box>
        )
      }
    }
    if (
      !!result &&
      (accountType === FulfillmentAccountType.Tremendous || accountType === FulfillmentAccountType.Ometria)
    ) {
      if (result.includes('Success')) {
        return <Box color="green">Success!</Box>
      }
      if (result.includes('Error')) {
        return <Box color="red">Please make sure the API key is correct.</Box>
      }
    }
    return <Box>{result}</Box>
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik<Fields>
        initialValues={{
          toEmail: '',
          fromEmail: '',
          emailTemplateName: '',
          templateId: '',
          loyaltyTierId: '',
          discountCodeFieldName: '',
          discountCode: '',
          igUsername: '',
          subject: '',
          eventId: '',
          value: 0,
          campaignId: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmitForm}
      >
        <Form>
          <DialogTitle>Test Integration</DialogTitle>
          <DialogContent>
            <div>
              {fields.has('toEmail') && (
                <Field
                  className={classes.field}
                  component={TextField}
                  label="Recipient Email"
                  type="email"
                  name="toEmail"
                />
              )}
              {fields.has('fromEmail') && (
                <Field
                  className={classes.field}
                  component={TextField}
                  label="From Email"
                  type="email"
                  name="fromEmail"
                />
              )}
              {fields.has('igUsername') && (
                <Field
                  className={classes.field}
                  component={TextField}
                  label="Instagram Username"
                  type="text"
                  name="igUsername"
                />
              )}
              {fields.has('emailTemplateName') && (
                <Field
                  className={classes.field}
                  component={TextField}
                  name="emailTemplateName"
                  label="Email Template Name"
                  type="text"
                />
              )}
              {fields.has('templateId') && (
                <Field
                  className={classes.field}
                  component={TextField}
                  name="templateId"
                  label="Template ID"
                  type="text"
                />
              )}
              {fields.has('subject') && (
                <Field className={classes.field} component={TextField} name="subject" label="Subject" type="text" />
              )}
              {fields.has('loyaltyTierId') && (
                <Field
                  className={classes.field}
                  component={TextField}
                  name="loyaltyTierId"
                  label="Loyalty Tier ID"
                  type="text"
                />
              )}
              {fields.has('discountCodeFieldName') && (
                <Field
                  className={classes.field}
                  component={TextField}
                  name="discountCodeFieldName"
                  label="Discount Code Field Name"
                  type="text"
                />
              )}
              {fields.has('discountCode') && (
                <Field
                  className={classes.field}
                  component={TextField}
                  name="discountCode"
                  label="Test Discount Code"
                  type="text"
                />
              )}
              {fields.has('eventId') && (
                <Field className={classes.field} component={TextField} name="eventId" label="Event ID" type="text" />
              )}
              {fields.has('campaignId') && (
                <Field className={classes.field} component={TextField} name="campaignId" label="Bluecore Campaign ID" type="text" />
              )}
              {fields.has('value') && (
                <Field className={classes.field} component={TextField} name="value" label="Value" type="text" />
              )}
            </div>
            {displayTestResult(result, integration.accountType)}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" disabled={testing}>
              Test
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default TestIntegration
