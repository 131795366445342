import React, { useState, useRef } from 'react'
import {
  Box,
  Button,
  Typography,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { ReactComponent as ChevronDownIcon } from '../../icons/chevron-down_minor.svg'
import FilterMenu, { Filter } from '../FilterMenu'

interface NewFilterForm {
  newFilterName: string
}

const useStyles = makeStyles({
  h7: {
    //MUI doesn't have a h7 whereas the mocks did
    fontSize: '1.125rem',
    lineHeight: 1.5,
    fontWeight: 600,
  },
  saveFilterNameTextField: {
    marginTop: 8,
    width: '100%',
  },
})

const newFilterSchema = yup.object({
  newFilterName: yup
    .string()
    .trim()
    .required()
    .label('Search name'),
})

interface SavedFilterBarProps {
  selectedFilter: Filter | null
  defaultText: string
  canSaveFilter: boolean
  filters: Filter[]
  setSaveFilterDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  saveFilterDialogOpen: boolean
  onDeleteFilter(id: string): void
  onSelectSavedFilter(id: string): void
  onCreateFilter(name: string): void
}

function SavedFilterBar({
  selectedFilter,
  defaultText,
  canSaveFilter,
  filters,
  setSaveFilterDialogOpen,
  saveFilterDialogOpen = false,
  onDeleteFilter,
  onSelectSavedFilter,
  onCreateFilter,
}: SavedFilterBarProps): React.ReactElement {
  const classes = useStyles()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [filterMenuOpen, setFilterMenuOpen] = useState(false)
  const [confirmDeleteFilter, setConfirmDeleteFilter] = useState<Filter | null>(null)

  function handleSaveFilter({ newFilterName }: NewFilterForm): void {
    setSaveFilterDialogOpen(false)
    onCreateFilter(newFilterName)
  }

  function handleClickTrash(id: string): void {
    const deleteFilter = filters.find(f => f.id === id)
    if (deleteFilter) {
      setConfirmDeleteFilter(deleteFilter)
    }
  }

  function handleDeleteFilter(): void {
    if (!confirmDeleteFilter) return
    setConfirmDeleteFilter(null)
    onDeleteFilter(confirmDeleteFilter.id)
  }

  return (
    <>
      <Box mb={7} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          ref={buttonRef}
          onClick={() => setFilterMenuOpen(true)}
          endIcon={<ChevronDownIcon height={16} width={16} />}
        >
          <Typography className={classes.h7}>{selectedFilter?.name || defaultText}</Typography>
        </Button>
      </Box>
      <FilterMenu
        anchorEl={buttonRef.current}
        open={filterMenuOpen}
        filters={filters}
        onSelect={(id: string) => {
          setFilterMenuOpen(false)
          onSelectSavedFilter(id)
        }}
        onCancel={() => setFilterMenuOpen(false)}
        selectedFilterIds={new Set(selectedFilter ? [selectedFilter.id] : [])}
        onDelete={handleClickTrash}
      />
      <Dialog open={saveFilterDialogOpen} onClose={() => setSaveFilterDialogOpen(false)}>
        {saveFilterDialogOpen && (
          <Formik<NewFilterForm>
            initialValues={{
              newFilterName: '',
            }}
            validationSchema={newFilterSchema}
            onSubmit={handleSaveFilter}
          >
            <Form>
              <DialogTitle>Save Search</DialogTitle>
              <DialogContent>
                <Typography>Give your search a unique name:</Typography>
                <Field
                  component={TextField}
                  label="Search name"
                  type="text"
                  name="newFilterName"
                  className={classes.saveFilterNameTextField}
                  autoFocus
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setSaveFilterDialogOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  OK
                </Button>
              </DialogActions>
            </Form>
          </Formik>
        )}
      </Dialog>
      <Dialog open={!!confirmDeleteFilter} onClose={() => setConfirmDeleteFilter(null)}>
        <DialogTitle>Delete Search?</DialogTitle>
        <DialogContent>Are you sure you want to delete search: {confirmDeleteFilter?.name}?</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setConfirmDeleteFilter(null)}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleDeleteFilter}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SavedFilterBar
