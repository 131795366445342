import React, { useState, useEffect, useRef } from 'react'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Link } from '@material-ui/core'
import { NetworkStatus } from '@apollo/client'
import { useContentUserDataQuery } from './operations/content-user-data.generated'
import { MentionFilterInput, MentionSort, AccountProductName, SortDirection, IgMediaPostType } from '../gql-global'
import ListSort from '../components/lists/ListSort'
import ListCount from '../components/lists/ListCount'
import ListActions from '../components/lists/ListActions'
import ListFilters from '../components/lists/ListFilters'
import PostList from './PostList'
import { useMentionsListQuery } from './operations/mentions-list.generated'
import {
  sortOptions,
  tagStatusOptions,
  BULK_ACTION_LIMIT,
  BULK_ADD_LABEL_LIMIT,
  igMentionTypeOptions,
  mediaTypeOptions,
  postTypeOptions,
  ttMentionTypeOptions,
  messageSendingMethodOptions,
  challengeApprovalStatusOptions,
} from './constants'
import useFilterParams, { Filters } from './use-filter-params'
import useSortParam from './use-sort-param'
import { useDateRangeRef } from '../hooks/useDateRangeRef'
import ConnectedPostDetailModal, { useOpenPostParam } from '../components/ConnectedPostDetailModal'
import SyncPendingSnackbar from '../components/SyncPendingSnackbar'
import { ReactComponent as NoPostsImage } from '../images/no-posts.svg'
import { ReactComponent as Export } from '../icons/export.svg'
import { ReactComponent as PlusIcon } from '../icons/plus_minor.svg'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import ContainerError from '../components/ContainerError'
import Page from '../Page'
import withAuthorization from '../withAuthorization'
import { CONTENT_ROUTE } from './routes'
import { useExportMentionsLazyQuery } from './operations/export-mentions.generated'
import ListHeader from '../components/lists/ListHeader'
import LabelMenu from '../components/LabelMenu'
import {
  useAddMentionLabelBulkMutation,
  AddMentionLabelBulkMutationVariables,
} from './operations/add-mention-label-bulk.generated'
import {
  useCreateMentionLabelBulkMutation,
  CreateMentionLabelBulkMutationVariables,
} from './operations/create-mention-label-bulk.generated'
import useBulkActionState from './use-bulk-action-state'
import useTitle from '../utils/use-title'
import ContentSavedFilterBar from './ContentSavedFilterBar'
import { realizedDateRangeFromFilter } from '../utils/date-range-helper'
import { LabelsOption, DateRangeOption, Options, SelectionOption } from '../components/lists/ListFilters/types'
import { useQueryParam, NumberParam } from 'use-query-params'
import labelsCacheUpdate from '../mutations/labels-cache-update'
import useSelectionState, { SelectionState } from '../hooks/useSelectionState'
import { useContentSocialAccountQuery } from './operations/social-account.generated'
import useIntercomOnPage from '../hooks/useIntercomOnPage'
import { isTypeName } from '../types/utility'
import { makeMentionTypesWhere } from '../dashboard/utils'
import useHasFeature from '../hooks/useHasFeature'
import { cleanAllAnyNoneFilter, useDateInclusionExclusionWhereFilter } from '../utils/filter-params'
import { useToast } from '../components/Alert/ToastProvider'
import { useSocialAccountChallengesQuery } from '../customer/operations/query-social-account-challenges.generated'
import { LABEL_MANAGEMENT_ROUTE, SEGMENT_MANAGEMENT_ROUTE } from '../settings/routes'

function getBulkActionTexts(action: 'export' | 'label' | 'addLabel' | 'createLabel'): {
  title: string
  actionText: string
  limitText: string
} {
  if (action === 'export') {
    return {
      title: 'Export',
      actionText: 'export',
      limitText: BULK_ACTION_LIMIT.toLocaleString(),
    }
  }
  return {
    title: 'Add Label',
    actionText: 'add a label to',
    limitText: BULK_ADD_LABEL_LIMIT.toLocaleString(),
  }
}

const useWhereFilters = (
  filters: Filters,
  isInstagramAccount: boolean,
  hasTikTokHashtags: boolean,
): MentionFilterInput => {
  const currentPostedAtDateRange = filters.postedAt ? realizedDateRangeFromFilter(filters.postedAt) : null
  const reffedPostedAtDateRange = useDateRangeRef(currentPostedAtDateRange)

  const messageDate = useDateInclusionExclusionWhereFilter(filters.messageDate)
  return {
    caption: filters.captionKeywords?.length ? { keywords: filters.captionKeywords } : undefined,
    hashtag: filters.hashtagKeywords?.length
      ? { keywords: filters.hashtagKeywords.map(s => s.replace('#', '')) }
      : undefined,
    tagStatus: filters.tagStatus?.size ? { any: Array.from(filters.tagStatus) } : undefined,
    challengeMediaApproval: filters.challengeMediaApproval?.size
      ? { any: Array.from(filters.challengeMediaApproval) }
      : undefined,
    postedAt: reffedPostedAtDateRange || undefined,
    followerCount: filters.followerCount || undefined,
    engagementRate: filters.engagementRate
      ? { lte: filters.engagementRate.lte, gte: filters.engagementRate.gte }
      : undefined,
    impressions: filters.impressions ? { lte: filters.impressions.lte, gte: filters.impressions.gte } : undefined,
    postCount: filters.postCount ? { lte: filters.postCount.lte, gte: filters.postCount.gte } : undefined,
    labels:
      filters.labels && (filters.labels.all || filters.labels.none)
        ? {
            all: filters.labels.all || undefined,
            none: filters.labels.none || undefined,
          }
        : undefined,
    segments:
      filters.segments && (filters.segments.all || filters.segments.none)
        ? {
            all: filters.segments.all || undefined,
            none: filters.segments.none || undefined,
          }
        : undefined,
    campaigns:
      filters.campaigns && filters.campaigns.any
        ? {
            any: filters.campaigns.any || undefined,
          }
        : undefined,
    challengeIds:
      filters.challengeIds && filters.challengeIds.any
        ? {
            any: filters.challengeIds.any || undefined,
          }
        : undefined,
    ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
    mediaType: !!filters?.mediaType?.size ? { any: Array.from(filters.mediaType) } : undefined,
    postType: !!filters?.postType?.size ? { any: Array.from(filters.postType) } : undefined,
    unavailableMedia: filters.unavailableMedia,
    expiredStories: filters.expiredStories,
    messageTemplate:
      filters.messageTemplate && (filters.messageTemplate.all || filters.messageTemplate.none)
        ? {
            all: filters.messageTemplate.all || undefined,
            none: filters.messageTemplate.none || undefined,
          }
        : undefined,
    messageDate,
    messageKeywords: filters.messageKeywords,
    messageSendingMethod: cleanAllAnyNoneFilter(filters.messageSendingMethod),
  }
}

function selectionSize(selectionState: SelectionState<string>, total: number): number {
  if (selectionState instanceof Set) {
    return selectionState.size
  }
  return total
}

const PAGE_SIZE = 12
const TEMPLATE_LIMIT = 200

const Content: React.FC = () => {
  useTitle('Content')
  useIntercomOnPage('Content')
  const [selectionState, selectionDispatch] = useSelectionState<string>()
  const [canSelect, setCanSelect] = useState(false)
  const [bulkState, dispatch] = useBulkActionState()
  const { showToast } = useToast()
  const [saveFilterDialogOpen, setSaveFilterDialogOpen] = useState(false)
  const [addMentionLabel] = useAddMentionLabelBulkMutation({ update: labelsCacheUpdate })
  const [createMentionLabel] = useCreateMentionLabelBulkMutation({ update: labelsCacheUpdate })
  const { loading: userDataLoading, data: userData } = useContentUserDataQuery()
  const accountLabels = userData?.whoami?.account?.labels?.results || []
  const accountSegments = userData?.whoami?.account?.segments?.results || []
  const accountFilters = userData?.whoami?.account?.contentFilters

  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE, setViewing] = useQueryParam('viewing', NumberParam)
  const limitRef = useRef(viewing)
  const { filters, setFilters, isDirty } = useFilterParams()
  useEffect(() => {
    selectionDispatch({ type: 'reset' })
  }, [filters.captionKeywords, selectionDispatch])
  const [sortId, setSortId] = useSortParam()
  const currentSortId = sortId || sortOptions[0].id
  const socialAccountId = userData?.whoami?.preferences.selectedSocialAccountId || null

  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  const { data: socialAccountData } = useContentSocialAccountQuery({
    skip: !socialAccountId,
    variables: {
      id: socialAccountId || '',
      limit: TEMPLATE_LIMIT,
    },
  })
  const isIGSocialAccount =
    socialAccountData?.socialAccount && isTypeName(socialAccountData.socialAccount, 'IGSocialAccount')

  const accountCampaigns = socialAccountData?.socialAccount?.campaigns || []
  const accountCampaignsMultiOption = accountCampaigns.map(c => {
    return { id: c.id, name: c.program?.name || '' }
  })
  useEffect(() => {
    if (
      filters.campaignId &&
      accountCampaignsMultiOption &&
      !accountCampaignsMultiOption.find(c => c.id.toString() === filters.campaignId)
    ) {
      const newFilters = {
        ...filters,
        campaignId: undefined,
      }
      setFilters(newFilters)
    }
  }, [accountCampaignsMultiOption, filters, setFilters, socialAccountId])

  const { data: challengeData } = useSocialAccountChallengesQuery({
    skip: !socialAccountId,
    variables: {
      socialAccountId: socialAccountId || '',
      limit: 200,
    },
  })

  const socialAccountChallenges = (challengeData?.socialAccount?.challenges?.results || []).map(challenge => {
    return { id: challenge.id, name: challenge.name }
  })

  const messageTemplates =
    socialAccountData?.socialAccount &&
    isTypeName(socialAccountData?.socialAccount, 'IGSocialAccount') &&
    socialAccountData?.socialAccount?.messageTemplates
  const messageTemplatesList = messageTemplates ? messageTemplates.results : []

  const whereClause = useWhereFilters(filters, isIGSocialAccount ?? false, hasTikTokHashtags)
  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    socialAccountId: { any: [socialAccountId || ''] },
  }
  const {
    error,
    loading: mentionsLoading,
    data,
    fetchMore,
    networkStatus,
  } = useMentionsListQuery({
    skip: !socialAccountId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      socialAccountId: socialAccountId || '',
      sortBy: currentSortId,
      where: whereClause,
      limit: limitRef.current,
      activeStoryMentionsWhere,
    },
  })

  const exportUrlRef = useRef<string | null>(null)
  const [exportMentions, { data: exportData, error: exportError }] = useExportMentionsLazyQuery()
  const exportUrl = exportData?.mentions?.csvUrl
  useEffect(() => {
    if (exportUrl && exportUrl !== exportUrlRef.current) {
      exportUrlRef.current = exportUrl
      window.location.href = exportUrl
    }
  }, [exportUrl])
  useEffect(() => {
    if (exportError) {
      showToast({
        title: 'Error',
        message: `Error exporting posts. Please try again Later`,
        severity: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportError])
  const loading = userDataLoading || (mentionsLoading && networkStatus !== NetworkStatus.fetchMore)
  const [, setOpenPostParam] = useOpenPostParam()
  const newViewingCount = data?.mentions?.results.length || limitRef.current
  useEffect(() => setViewing((Math.ceil(newViewingCount / PAGE_SIZE) || 1) * PAGE_SIZE), [newViewingCount, setViewing])

  const loadMoreMentions = (): void => {
    if (data?.mentions?.cursor) {
      void fetchMore({
        variables: {
          cursor: data?.mentions?.cursor,
          limit: PAGE_SIZE,
        },
      })
    }
  }

  const onSelectedPost = (id: string): void => {
    setOpenPostParam(id)
  }

  const onSortChange = (s: MentionSort): void => {
    selectionDispatch({ type: 'reset' })
    setSortId(s)
  }

  function handleSelectAction(action: 'export' | 'label') {
    dispatch({
      type: 'start',
      action,
      selectedCount: selectionSize(selectionState, data?.mentions?.total || 0),
    })
  }

  function handleToggleCanSelect() {
    selectionDispatch({ type: 'reset' })
    setCanSelect(prev => !prev)
  }

  const onExport = (): void => {
    if (!selectionState) {
      return
    }

    let where: MentionFilterInput = {}
    let limit = BULK_ACTION_LIMIT
    if (selectionState === 'ALL') {
      where = whereClause
    } else {
      where = {
        id: {
          any: Array.from(selectionState).map(s => s.toString()),
        },
      }
      limit = selectionState.size
    }
    exportMentions({
      variables: {
        socialAccountId: socialAccountId || '',
        where,
        limit,
        sortBy: currentSortId,
      },
    })
  }

  const onCloseOverLimitDialog = (): void => {
    dispatch({ type: 'done' })
  }

  const onAddLabel = (labelId: number): void => {
    let variables: AddMentionLabelBulkMutationVariables = {
      labelId: labelId.toString(),
    }
    if (selectionState instanceof Set) {
      variables = { ...variables, mentionIds: Array.from(selectionState).map(id => id.toString()) }
    } else if (selectionState === 'ALL') {
      variables = {
        ...variables,
        socialAccountId: socialAccountId || '',
        sortBy: currentSortId,
        sortDirection: SortDirection.Desc,
        where: whereClause,
        limit: BULK_ADD_LABEL_LIMIT,
      }
    }
    addMentionLabel({
      variables: variables,
    })
      .then(result => {
        const labelName = result.data?.addMentionLabel?.label?.name
        const affectedTagsCount = result.data?.addMentionLabel?.mentions?.length
        showToast({
          title: 'Success: Adding Label To Post(s)',
          message: `Label '${labelName}' was applied to ${affectedTagsCount || ''} post${
            affectedTagsCount === 1 ? '' : 's'
          }.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Add Label To Post(s)',
          message: `Something went wrong adding the this label to the post(s), please try again`,
          severity: 'error',
        })
      })
  }

  const onCreateLabel = (labelName: string): void => {
    let variables: CreateMentionLabelBulkMutationVariables = {
      name: labelName,
    }
    if (selectionState instanceof Set) {
      variables = { ...variables, mentionIds: Array.from(selectionState).map(id => id.toString()) }
    } else if (selectionState === 'ALL') {
      variables = {
        ...variables,
        socialAccountId: socialAccountId || '',
        sortBy: currentSortId,
        sortDirection: SortDirection.Desc,
        where: whereClause,
        limit: BULK_ADD_LABEL_LIMIT,
      }
    }
    createMentionLabel({
      variables: variables,
    })
      .then(result => {
        const labelName = result.data?.createLabel?.label?.name
        const affectedTagsCount = result.data?.createLabel?.mentions?.length
        showToast({
          title: 'Success: Created Label',
          message: `Label '${labelName}' was created and added to ${affectedTagsCount || ''} post${
            affectedTagsCount === 1 ? '' : 's'
          }.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Creating Label and Adding to Post(s)',
          message: 'Something went wrong creating and adding this label to the post(s), please try again',
          severity: 'error',
        })
      })
  }

  const onConfirmBulkAction = (): void => {
    if (bulkState?.step !== 'confirmation') {
      return
    }

    switch (bulkState.action) {
      case 'createLabel':
        onCreateLabel(bulkState.name)
        break
      case 'addLabel':
        onAddLabel(bulkState.id)
        break
      case 'export':
        onExport()
        break
    }
    dispatch({ type: 'done' })
  }

  const hasHitLabelLimit = !!userData?.whoami?.account?.organization.labelLimit.hasHitLimit
  const hasCustomers = userData?.whoami?.account?.organization.activeProducts?.some(
    p => p === AccountProductName.Customer,
  )
  const hasCampaigns = userData?.whoami?.account?.organization.activeProducts?.some(
    p => p === AccountProductName.Campaign,
  )

  const labelIdsAlreadyOnAllSelectedPosts = new Set<number>()
  accountLabels.forEach(label => {
    const numSelectedPostsInThatSegment =
      data?.mentions?.results.filter(
        p => selectionState instanceof Set && selectionState.has(p.id) && p.labels?.some(l => l.id === label.id),
      ).length || 0
    if (
      numSelectedPostsInThatSegment > 0 &&
      selectionState instanceof Set &&
      numSelectedPostsInThatSegment === selectionState.size
    ) {
      labelIdsAlreadyOnAllSelectedPosts.add(label.id)
    }
  })

  const segmentFilterOption = hasCustomers
    ? [
        {
          name: 'segments',
          type: 'labels' as const,
          label: 'Segments',
          entity: 'segment' as const,
          selectionOptions: accountSegments,
          includeField: 'all' as const,
          seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
        },
      ]
    : []

  let contentOptions: Options = [
    {
      name: 'postedAt',
      type: 'dateRange',
      label: 'Post Date',
      entity: 'normalPlusHours',
    },
    {
      name: 'captionKeywords',
      type: 'keywords',
      label: 'Caption',
      useChips: true,
      allowSpaces: true,
    },
    {
      name: 'hashtagKeywords',
      type: 'keywords',
      label: 'Hashtag List',
      useChips: true,
      prependChar: '#',
    },
    {
      name: 'labels',
      type: 'labels' as const,
      label: 'Label',
      entity: 'label' as const,
      selectionOptions: accountLabels,
      includeField: 'all' as const,
      seeAllLink: LABEL_MANAGEMENT_ROUTE.path,
    },
    ...(hasCampaigns && accountCampaigns && accountCampaigns.length > 0
      ? [
          {
            name: 'campaigns',
            type: 'labels',
            label: 'Programs',
            entity: 'program',
            selectionOptions: accountCampaignsMultiOption,
            includeField: 'any' as const,
          } as LabelsOption,
        ]
      : []),
    ...(!!socialAccountChallenges.length
      ? [
          {
            name: 'challengeIds',
            type: 'labels',
            label: 'Challenges',
            entity: 'challenge',
            selectionOptions: socialAccountChallenges,
            includeField: 'any' as const,
          } as LabelsOption,
        ]
      : []),
    {
      name: 'messagesGroup',
      type: 'parent' as const,
      label: 'Messages',
      children: [
        {
          name: 'messageTemplate',
          type: 'labels' as const,
          label: 'Template Name ',
          entity: 'message' as const,
          selectionOptions: messageTemplatesList,
          includeField: 'all' as const,
        },
        {
          name: 'messageDate',
          type: 'dateRange',
          label: 'Message Date',
          entity: 'normalPlusHours',
          exclude: true,
        } as DateRangeOption,
        {
          name: 'messageKeywords',
          type: 'keywords' as const,
          label: 'Message Keywords ',
          includeField: 'all' as const,
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'messageSendingMethod',
          type: 'selection',
          label: 'Sending Method',
          selectionOptions: messageSendingMethodOptions,
          exclude: true,
        } as SelectionOption,
      ],
    },
    {
      name: 'customerInfo',
      type: 'parent',
      label: 'Customer Info',
      children: [
        {
          name: 'followerCount',
          type: 'numericalRange',
          label: 'Follower Count',
          min: 0,
          max: 1_000_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
        {
          type: 'numericalRange',
          name: 'postCount',
          label: 'Post Count',
          min: 0,
          max: 5000,
          sliderRange: {
            gte: 0,
            lte: 1000,
          },
        },
        ...segmentFilterOption,
      ],
    },
    {
      name: 'postInfo',
      type: 'parent',
      label: 'Post Info',
      children: [
        ...(isIGSocialAccount || hasTikTokHashtags
          ? [
              {
                name: 'mentionType',
                type: 'selection',
                label: 'Mention Type',
                selectionOptions: isIGSocialAccount ? igMentionTypeOptions : ttMentionTypeOptions,
              } as SelectionOption,
            ]
          : []),
        {
          name: 'mediaType',
          type: 'selection',
          label: 'Media Type',
          selectionOptions: mediaTypeOptions,
        } as SelectionOption,
        ...(isIGSocialAccount
          ? [
              {
                name: 'postType',
                type: 'selection',
                label: 'Post Type',
                selectionOptions: postTypeOptions,
              } as SelectionOption,
            ]
          : []),
        {
          name: 'engagementRate',
          type: 'numericalRange',
          label: 'Engagement Rate',
          numberFormat: 'percent',
          sliderRange: { gte: 0, lte: 150 },
          min: 0,
          max: 100_000,
        },
        {
          name: 'impressions',
          type: 'numericalRange',
          label: 'Impressions',
          min: 0,
          max: 10_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
      ],
    },
    {
      name: 'postStatus',
      type: 'parent',
      label: 'Post Status',
      children: [
        {
          name: 'tagStatus',
          type: 'selection',
          label: 'Program Status',
          selectionOptions: tagStatusOptions,
        } as SelectionOption,
        {
          name: 'challengeMediaApproval',
          type: 'selection',
          label: 'Challenge Status',
          selectionOptions: challengeApprovalStatusOptions,
        },
      ],
    },
  ]

  const excludeOptions = [
    {
      name: 'labels',
      type: 'labels' as const,
      label: 'Label',
      entity: 'label' as const,
      selectionOptions: accountLabels,
      includeField: 'all' as const,
      seeAllLink: LABEL_MANAGEMENT_ROUTE.path,
    },
    {
      name: 'messagesGroup',
      type: 'parent' as const,
      label: 'Messages',
      children: [
        {
          name: 'messageTemplate',
          type: 'labels' as const,
          label: 'Template Name ',
          entity: 'message' as const,
          selectionOptions: messageTemplatesList,
        },
        {
          name: 'messageDate',
          type: 'dateRange',
          label: 'Message Date',
          entity: 'normalPlusHours',
          exclude: true,
        } as DateRangeOption,
        {
          name: 'messageKeywords',
          type: 'keywords' as const,
          label: 'Message Keywords ',
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'messageSendingMethod',
          type: 'selection',
          label: 'Sending Method',
          selectionOptions: messageSendingMethodOptions,
          exclude: true,
        } as SelectionOption,
      ],
    },
    ...segmentFilterOption,
    ...(isIGSocialAccount
      ? [
          {
            name: 'expiredStories',
            type: 'directSelection' as const,
            label: 'Expired Stories',
            addValue: true,
            excludeValue: false,
          },
          {
            name: 'unavailableMedia',
            type: 'directSelection' as const,
            label: 'Unavailable Media',
            addValue: true,
            excludeValue: false,
          },
        ]
      : []),
  ]

  return (
    <Page>
      {socialAccountId && <SyncPendingSnackbar socialAccountId={socialAccountId} />}
      <div data-intercom-target="Content Saved Filter Bar">
        <ContentSavedFilterBar
          filters={filters}
          setFilters={setFilters}
          isDirty={isDirty}
          accountId={userData?.whoami?.account?.id}
          accountFilters={accountFilters}
          isInstagramAccount={isIGSocialAccount ?? false}
          setSaveFilterDialogOpen={setSaveFilterDialogOpen}
          saveFilterDialogOpen={saveFilterDialogOpen}
        />
      </div>
      <Box>
        <Box>
          <ListFilters
            contentType="content"
            isDirty={isDirty}
            page="Content"
            onChangeFilters={filters => {
              limitRef.current = PAGE_SIZE
              selectionDispatch({ type: 'reset' })
              setFilters(filters)
            }}
            options={contentOptions}
            excludeOptions={excludeOptions}
            filters={filters}
            setSaveFilterDialogOpen={setSaveFilterDialogOpen}
          />
        </Box>
        <ListHeader>
          {!canSelect ? (
            <ListCount loading={loading} count={data?.mentions?.total} units="Posts" />
          ) : (
            <Box display="flex" alignItems="center">
              <Button variant="contained" onClick={() => selectionDispatch({ type: 'reset' })}>
                {selectionState === 'ALL'
                  ? `All ${data?.mentions?.total.toLocaleString()} Selected`
                  : `${(selectionState?.size || 0).toLocaleString()} Selected`}
              </Button>
              {selectionState !== 'ALL' && (
                <Box marginLeft={2}>
                  <Button color="primary" onClick={() => selectionDispatch({ type: 'selectAll' })}>
                    Select All {data?.mentions?.total.toLocaleString()}
                  </Button>
                </Box>
              )}
            </Box>
          )}
          <ListActions>
            {!!data?.mentions?.results.length && (
              <Button color={canSelect ? 'inherit' : 'primary'} onClick={handleToggleCanSelect}>
                {canSelect ? 'Cancel' : 'Select'}
              </Button>
            )}
            {!!selectionState && (
              <>
                <Button
                  variant="text"
                  color={'primary'}
                  startIcon={<PlusIcon width={16} />}
                  onClick={() => handleSelectAction('label')}
                >
                  Label
                </Button>
                <Button
                  variant="text"
                  color={'primary'}
                  startIcon={<Export />}
                  onClick={() => handleSelectAction('export')}
                >
                  Export
                </Button>
              </>
            )}
            <ListSort sortOptions={sortOptions} currentSortOptionId={currentSortId} onSortChange={onSortChange} />
          </ListActions>
        </ListHeader>
        {!error && (
          <PostList
            hasCampaigns={hasCampaigns}
            loading={loading}
            loadingMore={networkStatus === 3}
            max={limitRef.current}
            posts={data?.mentions?.results}
            onClickPost={onSelectedPost}
            selectable={canSelect}
            selectionDispatch={selectionDispatch}
            selectionState={selectionState}
          />
        )}
        {!loading && !error && !data?.mentions?.results.length && (
          <ContainerEmptyState image={NoPostsImage} text="No posts found for current filters" />
        )}
        {!loading && error && <ContainerError text="Error loading posts" />}
        {!loading && data?.mentions?.cursor && (
          <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={loadMoreMentions}
              disabled={networkStatus === NetworkStatus.fetchMore}
            >
              Load more
            </Button>
          </Box>
        )}
      </Box>
      <ConnectedPostDetailModal />
      <LabelMenu
        editable
        entity="label"
        seeAllLink={LABEL_MANAGEMENT_ROUTE.path}
        open={bulkState?.step === 'pickLabel'}
        variant="dialog"
        labels={userData?.whoami?.account?.labels?.results || []}
        onSelect={id => {
          dispatch({ type: 'selectedLabel', id })
        }}
        onCreate={(name: string) => {
          dispatch({ type: 'createLabel', name })
        }}
        onUpdate={() => dispatch({ type: 'done' })}
        onDelete={() => dispatch({ type: 'done' })}
        hasHitLimit={hasHitLabelLimit}
        onCancel={() => dispatch({ type: 'done' })}
        selectedLabelIds={labelIdsAlreadyOnAllSelectedPosts}
        allowedActions={[]}
      />
      <Dialog open={bulkState?.step === 'overLimit'} onClose={onCloseOverLimitDialog}>
        {bulkState?.step === 'overLimit' && (
          <>
            <DialogTitle>Cannot {getBulkActionTexts(bulkState.action).title}</DialogTitle>
            <DialogContent>
              <p>
                You cannot {getBulkActionTexts(bulkState.action).actionText} to more than{' '}
                {getBulkActionTexts(bulkState.action).limitText} posts at a time. Please select under{' '}
                {getBulkActionTexts(bulkState.action).limitText} posts or make a bulk request to{' '}
                <Link href="mailto:support@loudcrowd.com">support@loudcrowd.com</Link>
              </p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={onCloseOverLimitDialog}>
                OK
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog open={bulkState?.step === 'confirmation'} onClose={() => dispatch({ type: 'done' })}>
        {bulkState?.step === 'confirmation' && (
          <>
            <DialogTitle>{getBulkActionTexts(bulkState.action).title}?</DialogTitle>
            <DialogContent>
              <p>
                You are about to {getBulkActionTexts(bulkState.action).actionText}{' '}
                {selectionSize(selectionState, data?.mentions?.total || 0).toLocaleString()} posts.
              </p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => dispatch({ type: 'done' })}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={onConfirmBulkAction}>
                OK
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Page>
  )
}

export default withAuthorization(CONTENT_ROUTE)(Content)
